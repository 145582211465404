export const menuItemLabels: { [key: string]: string } = {
  "web-app": $localize`:@@navigation-module|admin-navigation|webapp:Application`,

  // Office configuration
  "office-configuration": $localize`:@@navigation-module|admin-navigation|office-configuration:Office configuration`,
  "office-settings": $localize`:@@navigation-module|admin-navigation|office-settings:Office settings`,
  "floors-spaces": $localize`:@@navigation-module|admin-navigation|floors-spaces:Floors & spaces`,
  // Desks, parking & others
  "desk-other-resources": $localize`:@@navigation-module|admin-navigation|desk-other-resources:Desks, parking & others`,
  "booking-settings": $localize`:@@navigation-module|admin-navigation|booking-settings:Booking settings`,
  "office-equipment": $localize`:@@navigation-module|admin-navigation|office-equipment:Equipment`,
  bookings: $localize`:@@navigation-module|admin-navigation|bookings:Bookings`,

  // Meeting rooms
  "meeting-room-settings": $localize`:@@navigation-module|admin-navigation|meeting-room-settings:Meeting rooms`,
  "meeting-room-general-setup": $localize`:@@navigation-module|admin-navigation|meeting-room-general-setup:General setup`,
  "meeting-room-catering-and-services": $localize`:@@navigation-module|admin-navigation|meeting-room-catering-and-services:Catering & services`,
  "meeting-room-kiosk": $localize`:@@navigation-module|admin-navigation|meeting-room-kiosk:Kiosk`,

  // Workforce engagement
  "hybrid-work-policies": $localize`:@@navigation-module|admin-navigation|hybrid-work-policies:Hybrid work policies`,
  "office-events-roles": $localize`:@@navigation-module|admin-navigation|office-events-settings:Events settings`,
  "office-events": $localize`:@@navigation-module|admin-navigation|office-events:Events`,

  // Analytics
  analytics: $localize`:@@navigation-module|admin-navigation|analytics:Analytics`,
  "schedule-analytics": $localize`:@@navigation-module|admin-navigation|schedule-analytics:Workforce analytics`,
  "office-analytics": $localize`:@@navigation-module|admin-navigation|office-analytics:Office analytics`,

  // Users & groups
  "users-and-groups": $localize`:@@navigation-module|admin-navigation|users-and-groups:Users & groups`,
  "user-settings": $localize`:@@navigation-module|admin-navigation|user-settings:User settings`,
  "user-group-settings": $localize`:@@navigation-module|admin-navigation|user-groups-settings:Group settings`,

  // Company
  integrations: $localize`:@@navigation-module|admin-navigation|integrations:Integrations`,
  "authorized-apps": $localize`:@@navigation-module|admin-navigation|authorized-apps:Authorized apps`,
  "api-keys": $localize`:@@navigation-module|admin-navigation|api-keys:API keys`,

  administration: $localize`:@@navigation-module|admin-navigation|administration:deskbird admin`,
};

export const publicApiKeyRestrictedMessage = $localize`:@@navigation-module|admin-navigation|contact-your-csv:Contact your CSM for more information`;
