import {
  Component,
  OnInit,
  inject,
  ChangeDetectionStrategy,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { FeatureAccessType, FeatureQuotaType } from "types";
import { ButtonIconComponent, SwitchComponent, DropdownComponent } from "db-ui";
import { TextInputComponent } from "projects/shared/src/lib/components/text-input/text-input.component";
import { FeatureFlagModel } from "../+store/feature-flag/model";
import { BehaviorSubject, combineLatest, map } from "rxjs";
import {
  FEATURE_ACCESS_STORAGE_KEY,
  FeatureStorage,
} from "./feature-access.interface";
import {
  FeatureQuota,
  FeatureQuotaLimit,
} from "types/interfaces/feature-access";

@Component({
  selector: "db-feature-access-control",
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SwitchComponent,
    ButtonIconComponent,
    TextInputComponent,
    DropdownComponent,
  ],
  templateUrl: "./feature-access-control.component.html",
  styleUrls: ["./feature-access-control.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureAccessControlComponent implements OnInit {
  private readonly featureFlagModel = inject(FeatureFlagModel);

  private readonly isPanelVisible$$ = new BehaviorSubject<boolean>(false);
  readonly isPanelVisible$ = this.isPanelVisible$$.asObservable();

  features = Object.values(FeatureAccessType);
  quotaTypes = Object.values(FeatureQuotaType);
  quotaLimits = Object.values(FeatureQuotaLimit).map((value) => ({
    label: value,
    value: value,
  }));
  private readonly storageKey = FEATURE_ACCESS_STORAGE_KEY;

  showFeatureControl$ = combineLatest([
    this.featureFlagModel.featureEnableMPPS$,
    this.featureFlagModel.featureEnableMPPSMocks$,
  ]).pipe(
    map(([mppsEnabled, mppsMocksEnabled]) => mppsEnabled && mppsMocksEnabled),
  );

  ngOnInit() {
    const quotas = this.quotaTypes.map((type) => ({
      name: type,
      quantity: 0,
      utilization: 0,
      limit: FeatureQuotaLimit.SOFT,
    }));
    if (!localStorage.getItem(this.storageKey)) {
      this.saveStorage({
        enabled: false,
        features: [],
        quotas,
      });
    } else {
      const storage = this.getStorage();
      if (!storage.quotas) {
        this.saveStorage({
          ...storage,
          quotas,
        });
      }
    }
  }

  togglePanel() {
    this.isPanelVisible$$.next(!this.isPanelVisible$$.value);
  }

  isFeatureEnabled(feature: string): boolean {
    const storage = this.getStorage();
    return storage.enabled && storage.features.includes(feature);
  }

  isMockingEnabled(): boolean {
    return this.getStorage().enabled;
  }

  toggleMocking(enabled: boolean) {
    const storage = this.getStorage();
    this.saveStorage({ ...storage, enabled });
  }

  toggleFeature(feature: string) {
    const storage = this.getStorage();
    const features = [...storage.features];
    const index = features.indexOf(feature);

    const newFeatures =
      index === -1
        ? [...features, feature]
        : features.filter((f) => f !== feature);

    this.saveStorage({ ...storage, features: newFeatures });
  }

  private getStorage(): FeatureStorage {
    const stored = localStorage.getItem(this.storageKey);
    return stored
      ? (JSON.parse(stored) as FeatureStorage)
      : { enabled: false, features: [], quotas: [] };
  }

  private saveStorage(storage: FeatureStorage) {
    localStorage.setItem(this.storageKey, JSON.stringify(storage));
  }

  getQuota(name: FeatureQuotaType): FeatureQuota {
    const storage = this.getStorage();
    return (
      storage.quotas?.find((q) => q.name === name) || {
        name,
        quantity: 0,
        utilization: 0,
        limit: FeatureQuotaLimit.SOFT,
      }
    );
  }

  updateQuotaField<T extends keyof FeatureQuota>(
    name: FeatureQuotaType,
    field: T,
    value: FeatureQuota[T],
  ) {
    const storage = this.getStorage();
    const quotaIndex = storage.quotas.findIndex((q) => q.name === name);
    const quotas = [...storage.quotas];

    if (quotaIndex === -1) {
      quotas.push({
        name: name as FeatureQuotaType,
        quantity: field === "quantity" ? (value as number) : 0,
        utilization: field === "utilization" ? (value as number) : 0,
        limit:
          field === "limit"
            ? (value as FeatureQuotaLimit)
            : FeatureQuotaLimit.SOFT,
      });
    } else {
      quotas[quotaIndex] = { ...quotas[quotaIndex], [field]: value };
    }

    this.saveStorage({ ...storage, quotas });
  }
}
