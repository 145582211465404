import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  FormsModule,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
} from "primeng/autocomplete";
import { TooltipModule } from "primeng/tooltip";
import { Subject, takeUntil, combineLatest, of, Observable } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  switchMap,
  map,
} from "rxjs/operators";
import {
  IncludeAvailableProperties,
  IUserGroupSearchResult,
  IUserInfo,
  UserStatus,
} from "types";
import { AvatarChipComponent } from "../avatar-chip/avatar-chip.component";
import { UserInfoComponent } from "../user-info/user-info.component";
import { SelectionItem, FormSelectionItem } from "./autocomplete-users.types";
import { IUserFilterService } from "shared";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const SEARCH_DEBOUNCE_TIME = 200;
const DEFAULT_LIMIT = 100;

@Component({
  selector: "db-autocomplete-users",
  standalone: true,
  imports: [
    CommonModule,
    AutoCompleteModule,
    FormsModule,
    AvatarChipComponent,
    UserInfoComponent,
    TooltipModule,
  ],
  templateUrl: "./autocomplete-users.component.html",
  styleUrls: ["./autocomplete-users.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AutocompleteUsersComponent,
      multi: true,
    },
  ],
})
/**
 * AutocompleteUsersComponent provides two interfaces for user selection:
 *
 * 1. Direct Usage Interface:
 *    - Uses initiallySelected* inputs (initiallySelectedUsers, initiallySelectedGroups, etc.)
 *    - Emits full SelectionItem objects via selectionChange output
 *    - Contains complete user/group information in the emitted data
 *    - Best for simple scenarios where you need the full user/group data immediately
 *
 * 2. Reactive Forms Interface:
 *    - Uses FormSelectionItem which only contains IDs/emails
 *    - Lighter weight for form state management
 *    - Automatically loads full user/group data when form value is set
 *    - Best for forms where you only need to store IDs/emails
 *
 * Example of Direct Usage:
 * ```html
 * <db-autocomplete-users
 *   [initiallySelectedUsers]="users"
 *   [initiallySelectedGroups]="groups"
 *   (selectionChange)="onSelectionChange($event)"
 * />
 * ```
 *
 * Example of Reactive Forms Usage:
 * ```html
 * <db-autocomplete-users
 *   formControlName="selection"
 * />
 * ```
 * ```typescript
 * form = new FormGroup({
 *   selection: new FormControl<FormSelectionItem[]>([])
 * });
 * ```
 */
export class AutocompleteUsersComponent
  implements OnChanges, OnDestroy, ControlValueAccessor
{
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly destroy$ = new Subject<void>();
  private readonly searchSubject = new Subject<string>();

  @Input() companyId!: string;
  @Input() placeholder!: string;
  @Input() emptyMessage!: string;
  @Input() limit = DEFAULT_LIMIT;
  @Input() excludeUserIds: string[] = [];
  @Input() userService!: IUserFilterService;
  @Input() dataTestId: string = "users-multi-select";
  @Input() label?: string;
  @Input() showAsterisk = false;
  @Input() optional = false;

  @Input() enableUserSelection = true;
  @Input() enableGroupSelection = false;
  @Input() enableExternalEmailSelection = false;
  @Input() enableGroupExpand = false;

  @Input() initiallySelectedUsers: IUserInfo[] = [];
  @Input() initiallySelectedUsersIds: string[] = [];
  @Input() initiallySelectedGroups: IUserGroupSearchResult[] = [];
  @Input() initiallySelectedGroupsIds: string[] = [];
  @Input() initiallySelectedExternalEmails: string[] = [];

  @Output() selectionChange = new EventEmitter<SelectionItem[]>();

  private initiallySelectedUserIdsTriggered = false;
  private initiallySelectedGroupsIdsTriggered = false;

  currentSelection: SelectionItem[] = [];
  suggestions: SelectionItem[] = [];

  groupTooltipContent: { [groupId: string]: string } = {};
  groupTooltipDisplayUserLimit = 20;
  groupTooltipExpandUserLimit = 20;

  constructor() {
    this.initializeSearchSubscription();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["initiallySelectedUsers"] ||
      changes["initiallySelectedUsersIds"] ||
      changes["initiallySelectedGroups"] ||
      changes["initiallySelectedGroupsIds"] ||
      changes["initiallySelectedExternalEmails"]
    ) {
      this.initializeSelectedItems();
    }
  }

  private initializeSelectedItems(): void {
    this.initializeSelectedUsers();
    this.initializeSelectedGroups();
    this.initializeSelectedExternalEmails();
    this.emitCurrentSelection();
  }

  private initializeSelectedUsers(): void {
    if (
      this.initiallySelectedUsersIds.length > 0 &&
      !this.initiallySelectedUserIdsTriggered &&
      this.companyId &&
      this.enableUserSelection
    ) {
      this.initiallySelectedUserIdsTriggered = true;
      this.loadUsersById(this.initiallySelectedUsersIds).subscribe((users) => {
        this.currentSelection = [
          ...this.currentSelection,
          ...users.map(this.mapUserToSelectionItem.bind(this)),
        ];
        this.emitCurrentSelection();
      });
    }

    if (this.initiallySelectedUsers.length > 0 && this.enableUserSelection) {
      this.currentSelection = [
        ...this.currentSelection,
        ...this.initiallySelectedUsers.map(
          this.mapUserToSelectionItem.bind(this),
        ),
      ];
    }
  }

  private initializeSelectedGroups(): void {
    if (
      this.initiallySelectedGroupsIds.length > 0 &&
      !this.initiallySelectedGroupsIdsTriggered &&
      this.enableGroupSelection
    ) {
      this.initiallySelectedGroupsIdsTriggered = true;
      this.loadGroupsById(this.initiallySelectedGroupsIds).subscribe(
        (groups) => {
          this.currentSelection.push(
            ...groups.map(this.mapGroupToSelectionItem.bind(this)),
          );
          this.emitCurrentSelection();
        },
      );
    }

    if (this.initiallySelectedGroups.length > 0 && this.enableGroupSelection) {
      this.currentSelection.push(
        ...this.initiallySelectedGroups.map(
          this.mapGroupToSelectionItem.bind(this),
        ),
      );
    }
  }

  private initializeSelectedExternalEmails(): void {
    if (
      this.initiallySelectedExternalEmails.length > 0 &&
      this.enableExternalEmailSelection
    ) {
      this.currentSelection.push(
        ...this.initiallySelectedExternalEmails.map(
          this.mapEmailToSelectionItem.bind(this),
        ),
      );
    }
  }

  private initializeSearchSubscription(): void {
    this.searchSubject
      .pipe(
        startWith(""),
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        debounceTime(SEARCH_DEBOUNCE_TIME),
        switchMap((searchValue) => {
          if (!searchValue || !this.companyId) {
            return of([]);
          }

          let usersRequest$: Observable<IUserInfo[]> = of([]);
          if (this.enableUserSelection) {
            usersRequest$ = this.userService
              .loadUsersForCompanyFiltered_v3({
                companyId: this.companyId,
                searchQuery: searchValue,
                excludeUserIds: [
                  ...this.excludeUserIds,
                  ...this.getSelectedUserIds(),
                ],
                offset: 0,
                limit: this.limit,
              })
              .pipe(map((response) => response.data));
          }

          let groupsRequest$: Observable<IUserGroupSearchResult[]> = of([]);
          if (this.enableGroupSelection) {
            groupsRequest$ = this.userService
              .loadGroupsForCompanyFiltered({
                companyId: this.companyId,
                searchQuery: searchValue,
                excludeGroupIds: this.getSelectedGroupIds(),
                include: ["userCount"],
                offset: 0,
                limit: this.limit,
              })
              .pipe(map((response) => response.data));
          }

          return combineLatest([usersRequest$, groupsRequest$]).pipe(
            map(([users, groups]) =>
              this.addSuggestions(users, groups, searchValue),
            ),
          );
        }),
      )
      .subscribe((suggestions) => {
        this.suggestions = suggestions;
        this.cdRef.detectChanges();
      });
  }

  private addSuggestions(
    users: IUserInfo[],
    groups: IUserGroupSearchResult[],
    searchQuery: string,
  ): SelectionItem[] {
    const suggestedUsers = users.map(this.mapUserToSelectionItem.bind(this));
    const suggestedGroups = groups.map(this.mapGroupToSelectionItem.bind(this));

    // If the searched email matches no user, add it as an external email
    const suggestedEmails: SelectionItem[] = [];
    if (this.enableExternalEmailSelection && suggestedGroups.length === 0) {
      const selectedEmails = this.getSelectedEmails();
      if (
        EMAIL_REGEX.test(searchQuery) &&
        !selectedEmails.includes(searchQuery.toLowerCase())
      ) {
        suggestedEmails.push(this.mapEmailToSelectionItem(searchQuery));
      }
    }

    return [...suggestedGroups, ...suggestedUsers, ...suggestedEmails];
  }

  onSearch(event: AutoCompleteCompleteEvent): void {
    this.searchSubject.next(event?.query?.trim());
  }

  onSelect(): void {
    this.emitCurrentSelection();
    this.searchSubject.next("");
  }

  onBlur(): void {
    this.searchSubject.next("");
  }

  onUnselect(): void {
    this.emitCurrentSelection();
  }

  onClearAll(): void {
    this.currentSelection = [];
    this.emitCurrentSelection();
  }

  private emitCurrentSelection(): void {
    // For direct usage via selectionChange output
    this.selectionChange.emit(this.currentSelection);

    // For reactive forms - convert SelectionItem to FormSelectionItem
    const formValue: FormSelectionItem[] = this.currentSelection.map((item) => {
      switch (item.type) {
        case "user":
          return { type: "user" as const, id: item.user.id! };
        case "group":
          return { type: "group" as const, id: item.group.id };
        case "externalEmail":
          return { type: "externalEmail" as const, email: item.email };
      }
    });
    this.onChange(formValue);
    this.onTouched();

    window.setTimeout(() => {
      this.cdRef.markForCheck();
      this.cdRef.detectChanges();
    }, 50);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private mapUserToSelectionItem(user: IUserInfo): SelectionItem {
    return {
      type: "user",
      key: `user-${user.id}`,
      user,
    };
  }

  private mapGroupToSelectionItem(
    group: IUserGroupSearchResult,
  ): SelectionItem {
    return {
      type: "group",
      key: `group-${group.id}`,
      group,
    };
  }

  private mapEmailToSelectionItem(email: string): SelectionItem {
    return {
      type: "externalEmail",
      key: `externalEmail-${email}`,
      email: email.toLowerCase(),
    };
  }

  private getSelectedUserIds(): string[] {
    return this.currentSelection
      .filter(
        (item): item is Extract<SelectionItem, { type: "user" }> =>
          item.type === "user",
      )
      .map((item) => item.user.id!);
  }

  private getSelectedGroupIds(): string[] {
    return this.currentSelection
      .filter(
        (item): item is Extract<SelectionItem, { type: "group" }> =>
          item.type === "group",
      )
      .map((item) => item.group.id);
  }

  private getSelectedEmails(): string[] {
    return this.currentSelection
      .filter(
        (item): item is Extract<SelectionItem, { type: "externalEmail" }> =>
          item.type === "externalEmail",
      )
      .map((item) => item.email.toLowerCase());
  }

  onGroupTooltipHover(group: IUserGroupSearchResult) {
    if (group.id in this.groupTooltipContent) {
      return;
    }

    this.groupTooltipContent[group.id] = "";

    if (!group.userCount) {
      return;
    }

    this.loadUsersByGroupId(
      group.id,
      this.groupTooltipDisplayUserLimit,
    ).subscribe((users) => {
      if (!users.total) {
        return;
      }

      let tooltipContent: string;
      const userCount = group.userCount!;
      if (userCount > this.groupTooltipDisplayUserLimit) {
        tooltipContent =
          $localize`:@@common|users:Users` +
          "\n" +
          users.data.map((user) => this.generateUserTooltip(user)).join("\n");

        const remainingCount = userCount - this.groupTooltipDisplayUserLimit;
        tooltipContent +=
          "\n" +
          $localize`:@@db-ui|autocomplete-users|tooltip-and-more:(And ${remainingCount}:count: more)`;
      } else {
        tooltipContent =
          $localize`:@@db-ui|autocomplete-users|tooltip-click-to-expand:Click to expand into users` +
          "\n" +
          users.data.map((user) => this.generateUserTooltip(user)).join("\n");
      }

      this.groupTooltipContent[group.id] = tooltipContent;
      this.cdRef.detectChanges();
    });
  }

  private generateUserTooltip(user: IUserInfo): string {
    return `• ${user.firstName} ${user.lastName} ${user.status === UserStatus.Inactive ? $localize`:@@common|inactive-with-brackets:(inactive)` : ""}`;
  }

  onGroupTooltipClick(group: IUserGroupSearchResult) {
    this.currentSelection = this.currentSelection.filter(
      (item) => item.type !== "group" || item.group.id !== group.id,
    );
    this.emitCurrentSelection();

    this.loadUsersByGroupId(
      group.id,
      this.groupTooltipExpandUserLimit,
    ).subscribe((users) => {
      this.currentSelection = [
        ...this.currentSelection,
        ...users.data
          .filter(
            (user) =>
              user.id &&
              !this.currentSelection.some(
                (item) => item.type === "user" && item.user.id === user.id,
              ),
          )
          .map(this.mapUserToSelectionItem.bind(this)),
      ];
      this.emitCurrentSelection();
    });
  }

  writeValue(value: FormSelectionItem[]): void {
    this.currentSelection = [];

    if (!value) {
      this.selectionChange.emit(this.currentSelection);
      this.cdRef.markForCheck();
      return;
    }

    const userIds = value
      .filter(
        (item): item is Extract<FormSelectionItem, { type: "user" }> =>
          item.type === "user",
      )
      .map((item) => item.id);

    const groupIds = value
      .filter(
        (item): item is Extract<FormSelectionItem, { type: "group" }> =>
          item.type === "group",
      )
      .map((item) => item.id);

    const externalEmails = value
      .filter(
        (item): item is Extract<FormSelectionItem, { type: "externalEmail" }> =>
          item.type === "externalEmail",
      )
      .map((item) => item.email);

    combineLatest([this.loadUsersById(userIds), this.loadGroupsById(groupIds)])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([users, groups]) => {
        this.currentSelection = [
          ...users.map(this.mapUserToSelectionItem.bind(this)),
          ...groups.map(this.mapGroupToSelectionItem.bind(this)),
          ...externalEmails.map(this.mapEmailToSelectionItem.bind(this)),
        ];
        this.selectionChange.emit(this.currentSelection);
        this.cdRef.markForCheck();
      });
  }

  registerOnChange(fn: (value: FormSelectionItem[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  private onChange: (value: FormSelectionItem[]) => void = () => {};
  private onTouched: () => void = () => {};

  private loadUsersById(userIds: string[]): Observable<IUserInfo[]> {
    if (!userIds.length || !this.enableUserSelection) {
      return of([]);
    }

    return this.userService
      .loadUsersForCompanyFiltered_v3({
        companyId: this.companyId,
        userIds,
        offset: 0,
        limit: userIds.length,
      })
      .pipe(
        map((response) => response.data),
        takeUntil(this.destroy$),
      );
  }

  private loadGroupsById(
    groupIds: string[],
  ): Observable<IUserGroupSearchResult[]> {
    if (!groupIds.length || !this.enableGroupSelection) {
      return of([]);
    }

    return this.userService
      .loadGroupsForCompanyFiltered({
        companyId: this.companyId,
        groupIds,
        include: ["userCount"],
        offset: 0,
        limit: groupIds.length,
      })
      .pipe(
        map((response) => response.data),
        takeUntil(this.destroy$),
      );
  }

  private loadUsersByGroupId(
    groupId: string,
    limit: number,
  ): Observable<{ data: IUserInfo[]; total: number }> {
    if (!groupId || !this.companyId) {
      return of({ data: [], total: 0 });
    }

    return this.userService
      .loadUsersForCompanyFiltered_v3({
        companyId: this.companyId,
        userGroups: [groupId],
        offset: 0,
        limit,
        include: ["status"],
        status: [UserStatus.Active, UserStatus.Inactive],
      })
      .pipe(takeUntil(this.destroy$));
  }
}
