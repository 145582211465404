import { ConnectedPosition, Overlay, OverlayRef } from "@angular/cdk/overlay";
import { inject, Injectable } from "@angular/core";
import { FeatureAccessType, FeatureQuotaType } from "types";
import { FeatureAccessService } from "./feature-access.service";
import { DialogService } from "primeng/dynamicdialog";
import { PopupContentComponent } from "../components/feature-access-paywall.component";
import { ComponentPortal } from "@angular/cdk/portal";
import { filter, map, take } from "rxjs";

/**
 * Service to manage and display popups (dialogs and popovers) dynamically.
 * Supports Angular CDK-based popovers for flexible positioning and PrimeNG dialogs.
 */
@Injectable()
export class FeatureAccessPaywallService {
  private dialogService: DialogService = inject(DialogService);
  private featureAccessService: FeatureAccessService =
    inject(FeatureAccessService);
  private overlay: Overlay = inject(Overlay);
  private readonly contactEmail = "support@deskbird.com";

  private overlayRef: OverlayRef | null = null;

  /**
   * Triggers a popup (dialog or popover) based on feature usage and quota limits.
   *
   * @param type - Specifies the type of popup to display ('dialog' or 'popover').
   * @param quotaName - The feature quota to check.
   * @param currentFeatureUses - The current usage of the feature.
   * @param config - Configuration for the popup content.
   * @param event - (Optional) Mouse event for positioning the popover.
   * @param placement - (Optional) Placement for the popover ('top', 'left', 'right', 'bottom').
   * * @param checkType - (Optional) Type of check to perform ('quota' or 'access'). Default is 'quota'.
   * @returns An Observable with information if paywall was showed or not.
   */
  triggerPaywall(
    type: "dialog" | "popover",
    identifier: FeatureQuotaType | FeatureAccessType,
    currentFeatureUses: number,
    config: {
      header: string;
      description?: string;
      image?: string;
      buttonCancel?: { label: string; action: () => void };
      buttonPrimary?: { label: string; action: () => void };
      width?: string;
    },
    event?: MouseEvent | HTMLElement,
    placement: "top" | "left" | "right" | "bottom" = "right",
    checkType: "quota" | "access" = "quota",
  ): void {
    if (!identifier || currentFeatureUses === undefined) {
      console.error("Quota name and current feature uses are required");
      return;
    }

    const check$ =
      checkType === "quota"
        ? this.featureAccessService
            .getFeatureQuotaLimit(identifier as FeatureQuotaType)
            .pipe(
              filter((limit) => limit !== null),
              map((limit) => currentFeatureUses >= (limit || Infinity)),
            )
        : this.featureAccessService
            .hasFeatureAccess(identifier as FeatureAccessType)
            .pipe(map((hasAccess) => !hasAccess));

    check$.pipe(take(1)).subscribe((shouldShowPaywall) => {
      if (shouldShowPaywall) {
        this.triggerPopup(type, config, event, placement);
      }
    });
  }

  /**
   * Displays a popup (dialog or popover) with the given configuration.
   *
   * @param type - Specifies the type of popup to display ('dialog' or 'popover').
   * @param config - Configuration for the popup content.
   * @param event - (Optional) Mouse event for positioning the popover.
   * @param placement - (Optional) Placement for the popover ('top', 'left', 'right', 'bottom').
   */
  private triggerPopup(
    type: "dialog" | "popover",
    config: {
      header: string;
      description?: string;
      image?: string;
      buttonCancel?: { label: string; action: () => void };
      buttonPrimary?: { label: string; action: () => void };
    },
    event?: MouseEvent | HTMLElement,
    placement: "top" | "left" | "right" | "bottom" = "top",
  ): void {
    const defaultPrimaryButton = {
      label: $localize`:@@auth-module|paywalls|contact-support:Contact customer success`,
      action: () => {
        const emailLink = encodeURI(`mailto:${this.contactEmail}`);
        window.open(emailLink, "_blank", "noopener,noreferrer");
      },
    };
    const finalConfig = {
      ...config,
      buttonPrimary: config.buttonPrimary || defaultPrimaryButton,
    };

    if (type === "dialog") {
      this.dialogService.open(PopupContentComponent, {
        header: finalConfig.header,
        width: "590px",
        data: finalConfig,
        styleClass: "paywall-modal",
      });
    } else if (type === "popover" && event) {
      this.showPopover(finalConfig, event, placement);
    }
  }

  /**
   * Displays a popover using Angular CDK's overlay system.
   *
   * @param config - Configuration for the popup content.
   * @param event - Mouse event for positioning the popover.
   * @param placement - Placement for the popover ('top', 'left', 'right', 'bottom').
   */
  private showPopover(
    config: {
      header: string;
      description?: string;
      image?: string;
      buttonCancel?: { label: string; action: () => void };
      buttonPrimary?: { label: string; action: () => void };
    },
    event: MouseEvent | HTMLElement,
    placement: "top" | "left" | "right" | "bottom",
  ): void {
    this.overlayRef?.dispose();

    // Map placement to CDK overlay positions
    const positions = this.getPositionForPlacement(placement);

    const element = (event as MouseEvent)?.target || event;

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(element as HTMLElement)
      .withPositions(positions);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: "cdk-overlay-transparent-backdrop",
      panelClass: `custom-overlay-panel`,
    });

    const popupPortal = new ComponentPortal(PopupContentComponent);
    const popupRef = this.overlayRef.attach(popupPortal);
    popupRef.instance.data = config;

    this.overlayRef.backdropClick().subscribe(() => this.overlayRef?.dispose());
  }

  /**
   * Maps a simple placement ('top', 'left', 'right', 'bottom') to CDK overlay positions.
   *
   * @param placement - Placement string.
   * @returns An array of CDK positions prioritized by the specified placement.
   */
  private getPositionForPlacement(
    placement: "top" | "left" | "right" | "bottom",
  ): ConnectedPosition[] {
    switch (placement) {
      case "top":
        return [
          {
            originX: "center",
            originY: "top",
            overlayX: "center",
            overlayY: "bottom",
          },
        ];
      case "left":
        return [
          {
            originX: "start",
            originY: "center",
            overlayX: "end",
            overlayY: "center",
          },
        ];
      case "right":
        return [
          {
            originX: "end",
            originY: "center",
            overlayX: "start",
            overlayY: "center",
          },
        ];
      case "bottom":
      default:
        return [
          {
            originX: "center",
            originY: "bottom",
            overlayX: "center",
            overlayY: "top",
          },
        ];
    }
  }

  /**
   * Closes any open paywall (both dialog and popover types)
   */
  closePaywall(): void {
    // Close dialog if open
    this.dialogService.dialogComponentRefMap.forEach((dialog) => {
      dialog.destroy();
    });

    // Close popover if open
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
}
