import { Injectable, inject } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { AuthModel, FeatureAccessRoutingService } from "auth-module";
import { RouterModel } from "router-module";
import { combineLatest, map, Observable, of, switchMap, take } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DefaultComponentActivate implements CanActivate {
  private routerModel = inject(RouterModel);
  private authModel = inject(AuthModel);
  private router = inject(Router);
  private featureAccessRoutingService = inject(FeatureAccessRoutingService);

  canActivate(): Observable<UrlTree> {
    return combineLatest([
      this.routerModel.isAdminAppEnv$,
      this.authModel.isRequiredToVisitSetupWizard$,
      this.authModel.isUserEmailVerified$,
    ]).pipe(
      switchMap(
        ([
          isAdminAppEnv,
          isRequiredToVisitSetupWizard,
          isUserEmailVerified,
        ]) => {
          if (!isUserEmailVerified) {
            return of(this.router.parseUrl("/login/verify-email"));
          }
          if (isRequiredToVisitSetupWizard) {
            return of(this.router.parseUrl("/login/sign-up-wizard"));
          }

          return (
            isAdminAppEnv
              ? this.featureAccessRoutingService.getAdministrationInitialRouteForCompany()
              : this.featureAccessRoutingService.getClientInitialRoute()
          ).pipe(map((url) => this.router.parseUrl(url)));
        },
      ),
      take(1),
    );
  }
}
