import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PaginatedResult } from "types/interfaces/paginated-response";
import { ILoadUsersFilteredRequest_v3 } from "types/interfaces/user-filtered-request";
import { IUserInfo } from "types/interfaces/user-info";
import { IUserGroupSearchResult } from "types/interfaces/users/user-group-search-result";
import { IUserFilterService as IUserFilterService } from "./user-filter.interface";

@Injectable({
  providedIn: "root",
})
export class UserFilterService implements IUserFilterService {
  private readonly http = inject(HttpClient);

  loadFollowedUsers(
    companyId: string,
    offset: number = 0,
    limit: number = 100,
  ): Observable<PaginatedResult<IUserInfo>> {
    const query = `companyId=${companyId}&followStatus=following&offset=${offset}&limit=${limit}`;
    return this.http.get<PaginatedResult<IUserInfo>>(`/api/v3/users?${query}`);
  }

  loadUsersForCompanyFiltered_v3({
    companyId,
    searchQuery,
    primaryOfficeIds,
    roles,
    userGroups,
    onlyUsersWithNoGroups,
    officeRolesIds,
    followStatus,
    userIds,
    excludeUserIds,
    status,
    sortField,
    sortOrder,
    offset,
    limit,
    include,
  }: ILoadUsersFilteredRequest_v3): Observable<PaginatedResult<IUserInfo>> {
    let query = `offset=${offset}&limit=${limit}`;

    if (companyId) {
      query = query.concat(`&companyId=${companyId}`);
    }

    if (primaryOfficeIds?.length) {
      query = query.concat(`&primaryOfficeIds=${primaryOfficeIds.join(",")}`);
    }

    if (followStatus) {
      query = query.concat(`&followStatus=${followStatus}`);
    }

    if (sortField) {
      query = query.concat(`&sortField=${sortField}&sortOrder=${sortOrder}`);
    }

    if (userGroups?.length) {
      query = query.concat(`&userGroups=${userGroups.join(",")}`);
    }

    if (searchQuery) {
      query = query.concat(`&searchQuery=${encodeURIComponent(searchQuery)}`);
    }

    if (roles?.length) {
      query = query.concat(`&roles=${roles.join(",")}`);
    }

    if (onlyUsersWithNoGroups) {
      query = query.concat(`&onlyUsersWithNoGroups=${onlyUsersWithNoGroups}`);
    }

    if (officeRolesIds?.length) {
      query = query.concat(`&officeRolesIds=${officeRolesIds.join(",")}`);
    }

    if (userIds?.length) {
      query = query.concat(`&userIds=${userIds.join(",")}`);
    }

    if (excludeUserIds?.length) {
      query = query.concat(`&excludeUserIds=${excludeUserIds.join(",")}`);
    }

    if (status?.length) {
      query = query.concat(`&status=${status.join(",")}`);
    }

    if (include?.length) {
      query = query.concat(`&include=${include.join(",")}`);
    }

    return this.http.get<PaginatedResult<IUserInfo>>(`/api/v3/users?${query}`);
  }

  loadGroupsForCompanyFiltered({
    companyId,
    searchQuery,
    groupIds,
    excludeGroupIds,
    include,
    offset,
    limit,
  }: {
    companyId: string;
    searchQuery?: string;
    groupIds?: string[];
    excludeGroupIds?: string[];
    include?: string[];
    offset: number;
    limit: number;
  }): Observable<PaginatedResult<IUserGroupSearchResult>> {
    const baseUrl = "v2/userGroups";
    let query = `companyId=${companyId}&offset=${offset}&limit=${limit}`;

    if (searchQuery) {
      query = query.concat(`&searchQuery=${encodeURIComponent(searchQuery)}`);
    }

    if (groupIds?.length) {
      query = query.concat(`&groupIds=${groupIds.join(",")}`);
    }

    if (excludeGroupIds?.length) {
      query = query.concat(`&excludeGroupIds=${excludeGroupIds.join(",")}`);
    }

    if (include?.length) {
      query = query.concat(`&include=${include.join(",")}`);
    }

    return this.http.get<PaginatedResult<IUserGroupSearchResult>>(
      `/api/${baseUrl}?${query}`,
    );
  }
}
