import { inject, Injectable } from "@angular/core";
import { AuthModel } from "../+store/model";
import { FeatureFlagModel } from "../+store/feature-flag/model";
import { filter, map, Observable, of, switchMap } from "rxjs";
import { combineLatestForFrame } from "shared";
import { UserRole } from "types/enums/user-role";
import { FeatureAccess } from "types/interfaces/feature-access";
import { FeatureAccessType } from "types";

@Injectable({
  providedIn: "root",
})
export class FeatureAccessRoutingService {
  private readonly authModel = inject(AuthModel);
  private readonly featureFlagModel = inject(FeatureFlagModel);

  /**
   * Retrieves the initial route for administration based on the user's role and feature access.
   *
   * @param companyBasePath - The base path for the company (e.g. /admin/company/375).
   * @returns An Observable that emits the initial route as a string.
   */
  getAdministrationInitialRouteForCompany(
    companyBasePath: string = "",
  ): Observable<string> {
    return combineLatestForFrame([
      this.authModel.authenticatedUser$,
      this.featureFlagModel.featureEnableMPPS$,
      this.authModel.selectors.isDeskbirdAdmin$,
    ]).pipe(
      switchMap(([authUser, mppsEnabled, isDbAdmin]) => {
        const hasAccessToOfficeAdministration =
          [UserRole.ADMIN, UserRole.OFFICE_ADMIN].includes(authUser.role) ||
          isDbAdmin;
        const hasAccessToPeopleAdministration =
          hasAccessToOfficeAdministration ||
          [UserRole.MANAGER, UserRole.GROUP_MANAGER].includes(authUser.role);

        if (!mppsEnabled) {
          return of(
            hasAccessToOfficeAdministration
              ? `${companyBasePath}/office`
              : hasAccessToPeopleAdministration
                ? `${companyBasePath}/people-analytics/people`
                : "/default",
          );
        }

        return this.authModel.userFeatureAccess$.pipe(
          filter(Boolean),
          map((featureAccess: FeatureAccess) => {
            if (
              hasAccessToOfficeAdministration &&
              this.hasSomeFeatureAccess(
                [FeatureAccessType.RESOURCE_BOOKING, FeatureAccessType.ROOMS],
                featureAccess,
              )
            ) {
              return `${companyBasePath}/office`;
            }

            if (
              hasAccessToOfficeAdministration &&
              this.hasSomeFeatureAccess(
                [
                  FeatureAccessType.WORKFORCE_ANALYTICS,
                  FeatureAccessType.OFFICE_EVENTS,
                  FeatureAccessType.SCHEDULING,
                  FeatureAccessType.SCHEDULING_CALENDAR_SYNC,
                  FeatureAccessType.HYBRID_WORK_POLICIES,
                ],
                featureAccess,
              )
            ) {
              return `${companyBasePath}/user`;
            }

            if (
              hasAccessToPeopleAdministration &&
              this.hasSomeFeatureAccess(
                FeatureAccessType.WORKFORCE_ANALYTICS,
                featureAccess,
              )
            ) {
              return `${companyBasePath}/people-analytics/people`;
            }

            return "/default";
          }),
        );
      }),
    );
  }

  getClientInitialRoute(): Observable<string> {
    return combineLatestForFrame([
      this.authModel.corporateInfo$.pipe(filter(Boolean)),
      this.featureFlagModel.featureEnableMPPS$,
    ]).pipe(
      switchMap(([corporateInfo, mppsEnabled]) => {
        if (!mppsEnabled) {
          return of(
            corporateInfo?.allowsScheduling
              ? "/planning"
              : corporateInfo?.allowsResourceBooking
                ? "/office"
                : "/no-access",
          );
        }

        return this.authModel.userFeatureAccess$.pipe(
          filter(Boolean),
          map((featureAccess: FeatureAccess) =>
            this.hasSomeFeatureAccess(
              FeatureAccessType.SCHEDULING,
              featureAccess,
            )
              ? "/planning"
              : "/office",
          ),
        );
      }),
    );
  }

  private hasSomeFeatureAccess(
    feature: FeatureAccessType | FeatureAccessType[],
    featureAccess: FeatureAccess,
  ): boolean {
    const features = Array.isArray(feature) ? feature : [feature];
    return features.some((f) => featureAccess.features.includes(f));
  }
}
