import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, UrlTree } from "@angular/router";
import { map, Observable, take } from "rxjs";
import { FeatureAccessRoutingService } from "auth-module";

@Injectable({ providedIn: "root" })
export class DefaultProfileActivate {
  private router = inject(Router);
  private featureAccessRoutingService = inject(FeatureAccessRoutingService);

  canActivate(route: ActivatedRouteSnapshot): Observable<UrlTree> {
    return this.featureAccessRoutingService.getClientInitialRoute().pipe(
      map((initialRoute) => {
        const profileId = route.params?.["profileId"];

        return this.router.parseUrl(
          profileId
            ? `${initialRoute}(user:profile/${profileId})`
            : initialRoute,
        );
      }),
      take(1),
    );
  }
}
