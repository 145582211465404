import { inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import {
  FeatureAccess,
  FeatureAccessType,
  FeatureQuota,
  FeatureQuotaLimit,
  FeatureQuotaType,
} from "types";
import { AuthModel } from "../+store/model";
import { FeatureFlagModel } from "../+store/feature-flag/model";

/**
 * Service responsible for managing feature access control.
 * Provides methods to check if user has access to specified features.
 */
@Injectable({
  providedIn: "root",
})
export class FeatureAccessService {
  private readonly authModel = inject(AuthModel);
  private readonly featureFlagModel = inject(FeatureFlagModel);

  /**
   * Checks if user has access to specified feature(s)
   * @param feature Single feature or array of features to check
   * @param condition Condition to apply when checking multiple features ('some' | 'every')
   * @returns Observable<boolean> indicating if user has access
   */
  hasFeatureAccess(
    feature: FeatureAccessType | FeatureAccessType[],
    condition: "some" | "every" = "some",
  ): Observable<boolean | null> {
    return this.featureFlagModel.featureEnableMPPS$.pipe(
      switchMap((mppsEnabled) => {
        if (!mppsEnabled) {
          return of(true);
        }

        return this.authModel.userFeatureAccess$.pipe(
          map((featureAccess: FeatureAccess | null) => {
            // Return null if featureAccess is not loaded yet
            if (!featureAccess) return null;

            const features = Array.isArray(feature) ? feature : [feature];
            const hasAccess =
              condition === "some"
                ? features.some((f) => featureAccess.features.includes(f))
                : features.every((f) => featureAccess.features.includes(f));
            return hasAccess;
          }),
        );
      }),
    );
  }

  /**
   * Checks if user has access to specified feature(s)
   * @param quota Single quota to check limit
   * @returns Observable<number> indicating how much user have uses of quota
   */
  getFeatureQuotaLimit(quota: FeatureQuotaType): Observable<number | null> {
    return this.featureFlagModel.featureEnableMPPS$.pipe(
      switchMap((mppsEnabled) => {
        if (!mppsEnabled) {
          return of(Infinity);
        }

        return this.authModel.userFeatureAccess$.pipe(
          map((featureAccess: FeatureAccess | null) => {
            // Return null if featureAccess is not loaded yet
            if (!featureAccess) return null;

            const featureQuota = featureAccess.quotas?.find(
              (q) => q.name === quota,
            );

            // If quota doesn't exist or quantity is -1, return Infinity
            // Otherwise return the quota quantity
            if (!featureQuota || featureQuota.quantity === -1) {
              return Infinity;
            }

            return featureQuota.quantity;
          }),
        );
      }),
    );
  }

  /**
   * Returns the initial feature quota for a given quota type.
   * @param quota The type of quota to get the initial value for.
   * @returns An observable that emits the initial feature quota or null if not found.
   */
  getFeatureQuota(quota: FeatureQuotaType): Observable<FeatureQuota | null> {
    return this.featureFlagModel.featureEnableMPPS$.pipe(
      switchMap((mppsEnabled) => {
        if (!mppsEnabled) {
          return of(null);
        }

        return this.authModel.userFeatureAccess$.pipe(
          map((featureAccess: FeatureAccess | null) => {
            const foundQuota = featureAccess?.quotas.find(
              (q) => q.name === quota,
            );

            if (!foundQuota) {
              return {
                name: quota,
                quantity: Infinity,
                limit:
                  quota === FeatureQuotaType.ROOMS
                    ? FeatureQuotaLimit.SOFT
                    : FeatureQuotaLimit.HARD,
                utilization: 0,
              } as FeatureQuota;
            }

            if (foundQuota.quantity === -1) {
              return {
                ...foundQuota,
                quantity: Infinity,
              } as FeatureQuota;
            }

            return foundQuota;
          }),
        );
      }),
    );
  }
}
