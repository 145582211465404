<!-- WEB APP -->

<db-menu-item
  [link]="[clientPartPrefix, 'default']"
  [style]="'nav-with-border-admin'"
  imgSrc="/assets/icons/arrow-back.svg"
  [title]="menuItemLabels['web-app']"
>
</db-menu-item>

<br />

<!-- OFFICE CONFIGURATION -->
@if (
  (isUserAdmin || isUserDeskbirdAdmin || isUserOfficeAdmin) &&
  ([adminPartPrefix, "company"] | exec: hasAccessToPage)
) {
  <db-menu-item-with-children
    *dbFeatureAccess="[
      FeatureAccessType.RESOURCE_BOOKING,
      FeatureAccessType.ROOMS,
      FeatureAccessType.SCHEDULING,
    ]"
    [link]="[
      adminPartPrefix,
      'company',
      companyId,
      officeId ? 'office' : 'office-redirect',
      officeId ? officeId : 'default',
      'dashboard',
      'essentials',
    ]"
    [hideIcons]="hideIcons"
    [linkActiveOptions]="{ exact: true }"
    queryParamsHandling="merge"
    [style]="'admin'"
    imgSrc="/assets/icons/configuration.svg"
    [title]="menuItemLabels['office-configuration']"
  >
    <db-menu-item
      [style]="'admin'"
      [hideIcons]="hideIcons"
      [link]="[
        adminPartPrefix,
        'company',
        companyId,
        officeId ? 'office' : 'office-redirect',
        officeId ? officeId : 'default',
        'dashboard',
        'essentials',
      ]"
      [title]="menuItemLabels['office-settings']"
      [hasAccessToPageFunction]="hasAccessToPage"
    />

    <db-menu-item
      *dbFeatureAccess="[
        FeatureAccessType.RESOURCE_BOOKING,
        FeatureAccessType.ROOMS,
      ]"
      [style]="'admin'"
      [hideIcons]="hideIcons"
      [link]="[
        adminPartPrefix,
        'company',
        companyId,
        officeId ? 'office' : 'office-redirect',
        officeId ? officeId : 'default',
        'floors-and-spaces',
      ]"
      [title]="menuItemLabels['floors-spaces']"
      [hasAccessToPageFunction]="hasAccessToPage"
    />
  </db-menu-item-with-children>
}

<!-- DESKS, PARKING & OTHERS -->
@if (
  (isUserAdmin || isUserDeskbirdAdmin || isUserOfficeAdmin) &&
  ([adminPartPrefix, "company"] | exec: hasAccessToPage)
) {
  <db-menu-item-with-children
    *dbFeatureAccess="FeatureAccessType.RESOURCE_BOOKING"
    [link]="[
      adminPartPrefix,
      'company',
      companyId,
      officeId ? 'office' : 'office-redirect',
      officeId ? officeId : 'default',
      'dashboard',
      'booking-settings',
    ]"
    [hideIcons]="hideIcons"
    [linkActiveOptions]="{ exact: true }"
    queryParamsHandling="merge"
    [style]="'admin'"
    imgSrc="/assets/icons/book.svg"
    [title]="menuItemLabels['desk-other-resources']"
  >
    <db-menu-item
      [style]="'admin'"
      [hideIcons]="hideIcons"
      [link]="[
        adminPartPrefix,
        'company',
        companyId,
        officeId ? 'office' : 'office-redirect',
        officeId ? officeId : 'default',
        'dashboard',
        'booking-settings',
      ]"
      [title]="menuItemLabels['booking-settings']"
      [hasAccessToPageFunction]="hasAccessToPage"
    />

    @if (officeEquipmentEnabled) {
      <db-menu-item
        [style]="'admin'"
        [hideIcons]="hideIcons"
        [link]="[
          adminPartPrefix,
          'company',
          companyId,
          officeId ? 'office' : 'office-redirect',
          officeId ? officeId : 'default',
          'office-equipment',
        ]"
        [title]="menuItemLabels['office-equipment']"
        [hasAccessToPageFunction]="hasAccessToPage"
      />
    }

    <db-menu-item
      [style]="'admin'"
      [hideIcons]="hideIcons"
      [link]="[
        adminPartPrefix,
        'company',
        companyId,
        officeId ? 'office' : 'office-redirect',
        officeId ? officeId : 'default',
        'bookings',
      ]"
      queryParamsHandling="merge"
      [title]="menuItemLabels['bookings']"
      [hasAccessToPageFunction]="hasAccessToPage"
    />
  </db-menu-item-with-children>
}

<!-- MEETING ROOM CONFIGURATION -->
@if (
  (isUserAdmin || isUserDeskbirdAdmin || isUserOfficeAdmin) &&
  ([adminPartPrefix, "company"] | exec: hasAccessToPage)
) {
  <db-menu-item-with-children
    *dbFeatureAccess="[FeatureAccessType.ROOMS]"
    [hideIcons]="hideIcons"
    [link]="[
      adminPartPrefix,
      'company',
      companyId,
      officeId ? 'office' : 'office-redirect',
      officeId ? officeId : 'default',
      'meeting-room-settings',
    ]"
    [linkActiveOptions]="{ exact: true }"
    queryParamsHandling="merge"
    [style]="'admin'"
    imgSrc="/assets/icons/meeting-room-area-type.svg"
    [title]="menuItemLabels['meeting-room-settings']"
  >
    <db-menu-item
      [style]="'admin'"
      [hideIcons]="hideIcons"
      [link]="[
        adminPartPrefix,
        'company',
        companyId,
        officeId ? 'office' : 'office-redirect',
        officeId ? officeId : 'default',
        'meeting-room-settings',
        'general-setup',
      ]"
      [title]="menuItemLabels['meeting-room-general-setup']"
      [hasAccessToPageFunction]="hasAccessToPage"
    />

    <db-menu-item
      [style]="'admin'"
      [link]="[
        adminPartPrefix,
        'company',
        companyId,
        officeId ? 'office' : 'office-redirect',
        officeId ? officeId : 'default',
        'meeting-room-settings',
        'catering-and-services',
      ]"
      *dbFeatureAccess="[FeatureAccessType.SERVICE_REQUESTS]"
      [title]="menuItemLabels['meeting-room-catering-and-services']"
      [hasAccessToPageFunction]="hasAccessToPage"
    />

    <db-menu-item
      [style]="'admin'"
      [link]="[
        adminPartPrefix,
        'company',
        companyId,
        officeId ? 'office' : 'office-redirect',
        officeId ? officeId : 'default',
        'meeting-room-settings',
        'kiosk',
      ]"
      [badge]="'Beta'"
      [title]="menuItemLabels['meeting-room-kiosk']"
      [hasAccessToPageFunction]="hasAccessToPage"
    >
    </db-menu-item>
  </db-menu-item-with-children>
}

<!-- WORKFORCE ENGAGEMENT -->
@if (
  (isUserAdmin || isUserDeskbirdAdmin) &&
  ([adminPartPrefix, "company", companyId, "hybrid-work-policies"]
    | exec: hasAccessToPage)
) {
  <db-menu-item-with-children
    *dbFeatureAccess="[FeatureAccessType.HYBRID_WORK_POLICIES]"
    [link]="[adminPartPrefix, 'company', companyId, 'hybrid-work-policies']"
    [hideIcons]="hideIcons"
    [linkActiveOptions]="{ exact: false }"
    queryParamsHandling="merge"
    [title]="menuItemLabels['hybrid-work-policies']"
    [style]="'admin'"
    imgSrc="/assets/icons/hybrid-work-policies-purple.svg"
  >
  </db-menu-item-with-children>
}

<!-- EVENTS -->
@if (
  hasPlanningAccess &&
  ([adminPartPrefix, "company", companyId, "office-events"]
    | exec: hasAccessToPage)
) {
  <db-menu-item-with-children
    *dbFeatureAccess="[FeatureAccessType.OFFICE_EVENTS]"
    [hideIcons]="hideIcons"
    [link]="[adminPartPrefix, 'company', companyId, 'office-events']"
    [linkActiveOptions]="{ exact: false }"
    queryParamsHandling="merge"
    [title]="menuItemLabels['office-events']"
    [style]="'admin'"
    imgSrc="/assets/icons/office-events-purple.svg"
  >
    <db-menu-item
      [style]="'admin'"
      [hideIcons]="hideIcons"
      [link]="[
        adminPartPrefix,
        'company',
        companyId,
        'office-events-roles',
        'manage',
      ]"
      [title]="menuItemLabels['office-events-roles']"
      [hasAccessToPageFunction]="hasAccessToPage"
    >
    </db-menu-item>
  </db-menu-item-with-children>
}

<!-- ANALYTICS -->
@if (
  ([adminPartPrefix, "company", companyId, "people-analytics", "people"]
    | exec: hasAccessToPage) ||
  ((isUserAdmin || isUserDeskbirdAdmin || isUserOfficeAdmin) &&
    ([
      adminPartPrefix,
      "company",
      companyId,
      officeId ? "office" : "office-redirect",
      officeId ? officeId : "default",
      "office-analytics",
      "office",
    ] | exec: hasAccessToPage))
) {
  <db-menu-item-with-children
    *dbFeatureAccess="[
      FeatureAccessType.WORKFORCE_ANALYTICS,
      FeatureAccessType.ROOMS,
      FeatureAccessType.RESOURCE_BOOKING,
    ]"
    [hideIcons]="hideIcons"
    [link]="[
      adminPartPrefix,
      'company',
      companyId,
      officeId ? 'office' : 'office-redirect',
      officeId ? officeId : 'default',
      'office-analytics',
      'office',
    ]"
    [linkActiveOptions]="{ exact: true }"
    queryParamsHandling="merge"
    [title]="menuItemLabels['analytics']"
    [style]="'admin'"
    imgSrc="/assets/icons/analytics.svg"
  >
    @if (isUserAdmin || isUserDeskbirdAdmin || isUserOfficeAdmin) {
      <db-menu-item
        *dbFeatureAccess="[
          FeatureAccessType.RESOURCE_BOOKING,
          FeatureAccessType.ROOMS,
        ]"
        [style]="'admin'"
        [hideIcons]="hideIcons"
        [link]="[
          adminPartPrefix,
          'company',
          companyId,
          officeId ? 'office' : 'office-redirect',
          officeId ? officeId : 'default',
          'office-analytics',
          'office',
        ]"
        [title]="menuItemLabels['office-analytics']"
        [hasAccessToPageFunction]="hasAccessToPage"
      />
    }

    <db-menu-item
      *dbFeatureAccess="[FeatureAccessType.WORKFORCE_ANALYTICS]"
      [style]="'admin'"
      [hideIcons]="hideIcons"
      [link]="[
        adminPartPrefix,
        'company',
        companyId,
        'people-analytics',
        'people',
      ]"
      [title]="menuItemLabels['schedule-analytics']"
      [hasAccessToPageFunction]="hasAccessToPage"
    />
  </db-menu-item-with-children>
}

<!-- USERS & GROUPS -->
@if (
  (isUserAdmin || isUserDeskbirdAdmin || isUserOfficeAdmin) &&
  ([adminPartPrefix, "company", companyId, "user"] | exec: hasAccessToPage)
) {
  <db-menu-item-with-children
    [link]="[adminPartPrefix, 'company', companyId, 'user']"
    [linkActiveOptions]="{ exact: true }"
    queryParamsHandling="merge"
    [style]="'admin'"
    [title]="menuItemLabels['users-and-groups']"
    [hideIcons]="hideIcons"
    imgSrc="/assets/icons/users.svg"
  >
    <db-menu-item
      [style]="'admin'"
      [hideIcons]="hideIcons"
      [link]="[adminPartPrefix, 'company', companyId, 'user', 'list']"
      [title]="menuItemLabels['user-settings']"
      [hasAccessToPageFunction]="hasAccessToPage"
    />

    <db-menu-item
      [style]="'admin'"
      [hideIcons]="hideIcons"
      [link]="[adminPartPrefix, 'company', companyId, 'user', 'user-group']"
      [title]="menuItemLabels['user-group-settings']"
      [hasAccessToPageFunction]="hasAccessToPage"
    />
  </db-menu-item-with-children>
}

<!-- INTEGRATIONS -->
@if (
  (isUserAdmin || isUserDeskbirdAdmin) &&
  ([adminPartPrefix, "company", companyId, "integrations"]
    | exec: hasAccessToPage)
) {
  <db-menu-item-with-children
    [hideIcons]="hideIcons"
    [style]="'integrations'"
    [link]="[adminPartPrefix, 'company', companyId, 'integrations']"
    [title]="menuItemLabels['integrations']"
    imgSrc="/assets/icons/integrations.svg"
  >
    @if (hasIntegrations) {
      <db-menu-item
        [style]="'admin'"
        [hideIcons]="hideIcons"
        [link]="[adminPartPrefix, 'company', companyId, 'integrations', 'list']"
        [title]="menuItemLabels['authorized-apps']"
        [hasAccessToPageFunction]="hasAccessToPage"
      />
    }
    <db-menu-item
      [style]="'admin'"
      [hideIcons]="hideIcons"
      [tooltip]="publicApiKeysTooltipLabel()"
      [link]="[
        adminPartPrefix,
        'company',
        companyId,
        'integrations',
        'public-api',
      ]"
      [title]="menuItemLabels['api-keys']"
      [hasAccessToPageFunction]="hasAccessToPage"
    />
  </db-menu-item-with-children>
}

<!-- ADMINISTRATION -->
@if (isUserDeskbirdAdmin) {
  <db-menu-item-with-children
    [link]="[adminPartPrefix, 'general', 'settings']"
    [style]="'admin'"
    [title]="menuItemLabels['administration']"
    [hideIcons]="hideIcons"
    imgSrc="/assets/icons/deskbird.svg"
  >
  </db-menu-item-with-children>
}
@if (isUserDeskbirdAdmin) {
  <div class="company-switch-container">
    <db-company-switcher></db-company-switcher>
  </div>
}

<div class="footer border-top">
  <div class="support-info">
    <a
      class="cta-help-center small-text"
      href="https://deskbird.zendesk.com/hc/en-us"
      target="_blank"
    >
      <img
        src="assets/icons/help-center-purple.svg"
        alt="help"
        class="help-icon question-icon"
      />
      <div class="text" i18n="@@navigation-module|help-center">Help center</div>
      <img
        src="assets/icons/link-purple.svg"
        alt="link"
        class="help-icon link-icon"
      />
    </a>
  </div>

  <div class="user-info">
    <div class="menu-trigger" (click)="menu.toggle($event)">
      <db-user-info
        [userInfo]="userInfo"
        [avatarSize]="'standard'"
      ></db-user-info>
    </div>
  </div>
  <db-menu #menu [items]="userMenuItems" [popup]="true"></db-menu>
</div>
